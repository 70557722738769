<template>
  <div>
    <v-text-field
      v-model="imageName"
      label="Choose image"
      :hint="hint"
      :persistent-hint="hint ? true : false"
      prepend-icon="attach_file"
      readonly
      @click="pickFile($event)"
      :error-messages="errorMessage"
      clearable
    ></v-text-field>
    <input
      type="file"
      style="height:1px;width:1px;visibility: hidden;"
      ref="image"
      accept="image/*"
      @change="onFilePicked"
    />
  </div>
</template>

<script>
export default {
  props: {
    value: {
      required: true,
      default: () => ({})
    },
    maxSize: {
      required: false,
      type: Number,
      default: 3 // 3MB
    },
    hint: {
      required: false,
      type: String,
      default: ""
    }
  },
  data: () => ({
    errorMessage: ""
  }),
  computed: {
    _value: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    },
    imageName: {
      get() {
        if (this._value && this._value.name) {
          return this._value.name;
        }

        return null;
      },
      set(value) {
        if (!value) {
          this._value = null;
          this.$refs.image.value = null;
        }
      }
    }
  },
  methods: {
    pickFile() {
      this.$refs.image.click();
    },

    onFilePicked(e) {
      // Reset error
      this.errorMessage = "";

      const files = e.target.files;

      let image = {
        name: "",
        file: "",
        url: "",
        width: "",
        height: ""
      };

      if (files[0] !== undefined) {
        image.file = files[0];
        image.name = image.file.name;

        // File size in MB
        let fileSize = image.file.size / 1024 / 1024;

        // Check if file is too large
        if (fileSize > this.maxSize) {
          this.errorMessage =
            "File is too big! Max " + this.maxSize + " MB";
          this._value = image;
          return;
        }

        // If ???
        if (image.name.lastIndexOf(".") <= 0) {
          return false;
        }

        // Read image preview
        let previewFileReader = new FileReader();
        previewFileReader.readAsDataURL(files[0]);
        previewFileReader.addEventListener("load", () => {
          image.url = previewFileReader.result;
        });

        // Read image dimensions (Width & Height)
        let img = new Image();
        img.src = window.URL.createObjectURL(image.file);
        img.onload = function() {
          image.width = this.width;
          image.height = this.height;
        };
      }

      this._value = image;
    }
  },
  created() {}
};
</script>
