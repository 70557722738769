<template>
  <div v-if="imageSrc" class="campaign-image-wrapper">
    <img
      v-show="imageLoaded"
      :src="imageSrc"
      class="rounded-top"
      :style="{
        width: '100%',
        height: `${calculatedHeight}px`,
        'object-fit': 'cover'
      }"
      @load="imageLoaded = true"
      @error="imageNotFound = true"
    />

    <v-chip
      class="ma-1 status-label text-subtitle-2"
      color="white"
      v-if="isMobile"
    >
      <v-icon x-small :class="getCampaignStatus(campaign).class"
        >fas fa-circle</v-icon
      >
      <v-list-item-content class="ml-3">
        <v-list-item-title>
          <span v-text="getCampaignStatus(campaign).label"></span>
        </v-list-item-title>
      </v-list-item-content>
    </v-chip>

    <v-skeleton-loader
      v-show="!imageLoaded"
      :loading="true"
      class="rounded"
      width="100%"
      type="image"
      ref="skeletonLoader"
    ></v-skeleton-loader>
    <div
      v-if="editMode"
      class="edit-image-icon cursor-pointer"
      @click="showCampaignUploadDialog = true"
    >
      <v-icon color="white" large>fal fa-edit</v-icon>
    </div>
    <campaign-image-upload-dialog
      v-if="editMode"
      v-model="showCampaignUploadDialog"
      :campaign="campaign"
      @save="onImageSaved()"
    ></campaign-image-upload-dialog>
  </div>
</template>
<script>
import CampaignImageUploadDialog from "../dialogs/CampaignImageUploadDialog";
import campaign_status from "@/helpers/mixins/campaign_status";

export default {
  components: {
    CampaignImageUploadDialog
  },
  mixins: [campaign_status],
  props: {
    src: {
      type: String,
      default: ""
    },
    height: {
      required: false,
      type: Number,
      default: 0
    },
    editMode: {
      required: false,
      type: Boolean,
      default: false
    },
    campaign: {
      required: false,
      type: Object
    }
  },
  data: () => ({
    imageNotFound: false,
    imageLoaded: false,
    imageSrc: false,
    showCampaignUploadDialog: false
  }),
  computed: {
    calculatedHeight() {
      if (this.height) {
        return this.height;
      }
      if (this.$vuetify.breakpoint.xsOnly) {
        return 202;
      } else if (this.$vuetify.breakpoint.smAndDown) {
        return 380;
      }
      return 500;
    },
    isMobile() {
      return window.innerWidth < 600 ? true : false;
    }
  },
  methods: {
    createImageSrc() {
      if (this.imageNotFound || !this.src) {
        this.imageSrc = `/img/campaign_default.jpg`;
      } else {
        this.imageSrc = this.src;
      }
      this.$nextTick(() => {
        this.$refs.skeletonLoader.$el.querySelector(
          ".v-skeleton-loader__image"
        ).style.height = `${this.calculatedHeight}px`;
      });
    },
    onImageSaved() {
      this.imageSrc = this.campaign.image
        ? this.campaign.image.url
        : this.campaign.image_url;
    }
  },
  mounted() {
    this.createImageSrc();
  }
};
</script>
<style lang="scss" scoped>
.rounded-top {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}
.status-label {
  position: absolute;
  z-index: 1;
  top: 10px;
  left: 10px;
}
.campaign-image-wrapper {
  position: relative;
  display: flex;
  .edit-image-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    background: rgba(0, 0, 0, 0.3);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  &:hover {
    .edit-image-icon {
      opacity: 1;
    }
  }
}
</style>
