export default {
  methods: {
    getCampaignStatus(campaign) {
      let meta = {
        label: "",
        class: ""
      };

      if (!campaign.published_at) {
        meta.label = "Draft";
        meta.name = "draft";
        meta.class = "grey--text";
      } else if (campaign.status.name === "not_started") {
        meta.label = "Awaiting start";
        meta.name = "not_started";
        meta.class = "warning--text";
      } else if (campaign.status.name === "ended") {
        meta.label = "Completed";
        meta.name = "ended";
        meta.class = "error--text";
      } else {
        meta.label = "Active";
        meta.name = "started";
        meta.class = "success--text";
      }

      return meta;
    }
  }
};
