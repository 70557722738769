export default {
  methods: {
    copyText(text) {
      let form = document.createElement("form");

      // Create tmp input used for copying value
      let input = document.createElement("input");
      input.setAttribute("type", "text");
      input.setAttribute("value", text);
      input.style.visibility = "hidden";

      // Get app element
      let app = document.getElementById("app");
      // Append input to form
      form.appendChild(input);
      // Append tmp form to app
      app.appendChild(form);

      // Select input value
      input.select();

      // Select TMP input
      let range = document.createRange();
      range.selectNodeContents(input);

      // Window selections
      let s = window.getSelection();
      s.removeAllRanges();
      s.addRange(range);

      // Select tmp input?
      input.setSelectionRange(0, 999999);

      let successful = false;

      // Try and copy
      try {
        successful = document.execCommand("copy");
      } catch (err) {
        successful = false;
      }

      if (successful) {
        this.setSnackSuccess("Copied to clipboard");
      } else {
        this.setSnackError("Automatic copying failed");
      }

      // Remove tmp form
      app.removeChild(form);
      // unselect the range
      window.getSelection().removeAllRanges();
    },
    copyElementText(element) {
      // If argument is string get element
      if (typeof element === "string") {
        element = document.getElementById(element);
      }

      // Select input value
      element.select();

      // Select TMP input
      let range = document.createRange();
      range.selectNodeContents(element);

      // Window selections
      let s = window.getSelection();
      s.removeAllRanges();
      s.addRange(range);

      // Select tmp input?
      element.setSelectionRange(0, 999999);

      let successful = false;

      // Try and copy
      try {
        successful = document.execCommand("copy");
        window.getSelection().removeAllRanges();
      } catch (err) {
        successful = false;
      }

      if (successful) {
        this.setSnackSuccess("Copied to clipboard");
      } else {
        this.setSnackError("Automatic copying failed");
      }
    }
  }
};
